
    //import CodePanel from './components/CodePanel.vue'
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'App',
        //components: {
        //    CodePanel
        //}
    });
